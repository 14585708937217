define("ember-concurrency/-private/task-factory", ["exports", "@ember/debug", "@ember/object", "@ember/object/events", "@ember/object/observers", "@ember/runloop", "ember-concurrency/-private/external/task-factory", "ember-concurrency/-private/task", "ember-concurrency/-private/task-properties", "ember-concurrency/-private/task-group", "ember-concurrency/-private/scheduler/ember-scheduler", "ember-concurrency/-private/ember-environment"], function (_exports, _debug, _object, _events, _observers, _runloop, _taskFactory, _task, _taskProperties, _taskGroup, _emberScheduler, _emberEnvironment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TaskFactory = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  let handlerCounter = 0;
  function registerOnPrototype(addListenerOrObserver, proto, names, taskName, taskMethod, once) {
    if (names && names.length > 0) {
      for (let i = 0; i < names.length; ++i) {
        let name = names[i];
        let handlerName = `__ember_concurrency_handler_${handlerCounter++}`;
        proto[handlerName] = makeTaskCallback(taskName, taskMethod, once);
        addListenerOrObserver(proto, name, null, handlerName);
      }
    }
  }
  function makeTaskCallback(taskName, method, once) {
    return function () {
      let task = (0, _object.get)(this, taskName);
      if (once) {
        (0, _runloop.scheduleOnce)('actions', task, method, ...arguments);
      } else {
        task[method].apply(task, arguments);
      }
    };
  }
  const ensureArray = possibleArr => Array.isArray(possibleArr) ? possibleArr : [possibleArr];
  (0, _taskFactory.registerModifier)('cancelOn', (factory, eventNames) => factory.addCancelEvents(...ensureArray(eventNames)));
  (0, _taskFactory.registerModifier)('observes', (factory, propertyPaths) => factory.addObserverKeys(...ensureArray(propertyPaths)));
  (0, _taskFactory.registerModifier)('on', (factory, eventNames) => factory.addPerformEvents(...ensureArray(eventNames)));
  class TaskFactory extends _taskFactory.TaskFactory {
    constructor() {
      super(...arguments);
      _defineProperty(this, "env", _emberEnvironment.EMBER_ENVIRONMENT);
    }
    createTask(context) {
      (true && !(this.taskDefinition) && (0, _debug.assert)(`Cannot create task if a task definition is not provided as generator function or encapsulated task.`, this.taskDefinition));
      let options = this.getTaskOptions(context);
      if (typeof this.taskDefinition === 'object') {
        return new _task.EncapsulatedTask(Object.assign({
          taskObj: this.taskDefinition
        }, options));
      } else {
        return new _task.Task(Object.assign({
          generatorFactory: args => this.taskDefinition.apply(context, args)
        }, options));
      }
    }
    createTaskGroup(context) {
      (true && !(!this.taskDefinition) && (0, _debug.assert)(`A task definition is not expected for a task group.`, !this.taskDefinition));
      let options = this.getTaskOptions(context);
      return new _taskGroup.TaskGroup(options);
    }
    addCancelEvents() {
      this._cancelEventNames = this._cancelEventNames || [];
      this._cancelEventNames.push(...arguments);
      return this;
    }
    addObserverKeys() {
      this._observes = this._observes || [];
      this._observes.push(...arguments);
      return this;
    }
    addPerformEvents() {
      this._eventNames = this._eventNames || [];
      this._eventNames.push(...arguments);
      return this;
    }
    getModifier(name) {
      let modifier = super.getModifier(name);
      if (!modifier && typeof _taskProperties.TaskProperty.prototype[name] === 'function') {
        // Shim for compatibility with user-defined TaskProperty prototype
        // extensions. To be removed when replaced with proper public API.
        modifier = _taskProperties.TaskProperty.prototype[name].bind(this);
      }
      (true && !(modifier) && (0, _debug.assert)(`Task option '${name}' is not recognized as a supported option.`, modifier));
      return modifier;
    }
    getScheduler(schedulerPolicy, stateTrackingEnabled) {
      return new _emberScheduler.default(schedulerPolicy, stateTrackingEnabled);
    }
    _setupEmberKVO(proto) {
      // TODO: Does this make sense in a post-Ember object world?

      registerOnPrototype(_events.addListener, proto, this._eventNames, this.name, 'perform', false);
      registerOnPrototype(_events.addListener, proto, this._cancelEventNames, this.name, 'cancelAll', false);
      registerOnPrototype(_observers.addObserver, proto, this._observes, this.name, 'perform', true);
    }

    // Provided for compatibility with ember-concurrency TaskProperty extension
    // methods
    get taskFn() {
      return this.taskDefinition;
    }
    set taskFn(fn) {
      this.setTaskDefinition(fn);
    }
  }
  _exports.TaskFactory = TaskFactory;
});